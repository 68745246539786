import firebase from 'firebase/app/dist/index.cjs';
import fire from './fire';

const auth = fire.auth();
const db = fire.firestore();
const storage = fire.storage();
const functions = fire.functions();

let perf = {};
let analytics = {};
if (typeof window !== 'undefined') {
	perf = fire.performance();
	analytics = fire.analytics();
}

const provider = new firebase.auth.GoogleAuthProvider();

const fieldValue = firebase.firestore.FieldValue;

// set up local emulator settings
if (process.env.NODE_ENV === 'test') {
	console.log('Running emulators: firestore, auth, functions');
	db.settings({ host: 'localhost:8080', ssl: false });
	auth.useEmulator('http://localhost:9099');
	functions.useFunctionsEmulator('localhost:5001');
	storage.useEmulator('localhost', 9199);
}

export { auth, provider, db, storage, functions, perf, analytics, fieldValue };
