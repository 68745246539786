import { useContext } from 'react';
import { Snackbar } from '@mui/material';
import { CommonContext } from 'lib/context/CommonContext';
import { CommonContextType } from 'types';

const Snackbars = () => {
	const { successMessage, errorMessage, setSuccessMessage, setErrorMessage } = useContext(
		CommonContext
	) as CommonContextType;

	const handleSnackbarClose = (e: any, reason: string) => {
		if (reason === 'clickaway') {
			return;
		}

		setSuccessMessage('');
		setErrorMessage('');
	};

	return (
		<>
			<Snackbar
				className='snackbar--error'
				open={errorMessage ? true : false}
				autoHideDuration={5000}
				onClose={handleSnackbarClose}
				message={errorMessage}
			/>
			<Snackbar
				className='snackbar--success'
				open={successMessage && !errorMessage ? true : false}
				autoHideDuration={3000}
				onClose={handleSnackbarClose}
				message={successMessage}
			/>
		</>
	);
};

export default Snackbars;
