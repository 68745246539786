import SmallLoader from 'components/Loader/SmallLoader';
import useScrollToTop from 'lib/hooks/useScrollToTop';

const PageLoader = () => {
	useScrollToTop();

	return (
		<div
			style={{
				height: '70vh',
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
			}}>
			<SmallLoader />
		</div>
	);
};

export default PageLoader;
