import axios from 'axios';
import { CommonContext } from 'lib/context/CommonContext';
import { useContext, useState } from 'react';
import { mutate } from 'swr';

/**
 *
 * @param {String} productId product to add to, or edit in the cart
 * @param {Boolean} edit editing or adding the product?
 * @param {Function} handleClose function to close the modal after action
 */
const useCart = (onClose: () => void, setWorking: (bool: boolean) => void) => {
	const { setSuccessMessage, setErrorMessage } = useContext(CommonContext);

	const addCartItem = ({
		id = null,
		product_id,
		sku_id,
		quantity,
	}: {
		id?: number | null;
		product_id: number;
		sku_id: number;
		quantity: number;
	}) => {
		setWorking(true);
		return axios
			.put(`/api/me/cart`, {
				id,
				product_id,
				sku_id,
				quantity,
			})
			.then(() => {
				setSuccessMessage('Updated cart');
				setWorking(false);
				onClose();
				mutate('/api/me');
			})
			.catch((e) => {
				setErrorMessage('Error updating cart');
				setWorking(false);
			});
	};

	const updateCartItem = ({ id, quantity }: { id: number; quantity: number }) => {
		if (quantity < 1) {
			return;
		}
		setWorking(true);
		return axios
			.put(`/api/me/cart`, {
				id,
				quantity,
			})
			.then(() => {
				setSuccessMessage('Updated cart');
				setWorking(false);
				onClose();
				mutate('/api/me');
			})
			.catch((e) => {
				setErrorMessage('Error updating cart');
				setWorking(false);
			});
	};

	const removeCartItem = (id: number) => {
		setWorking(true);
		return axios
			.delete(`/api/cart-item/${id}`)
			.then(() => {
				setSuccessMessage('Updated cart');
				setWorking(false);
				onClose();
				mutate('/api/me');
			})
			.catch((e) => {
				setErrorMessage('Error deleting item');
				setWorking(false);
			});
	};

	return {
		addCartItem,
		updateCartItem,
		removeCartItem,
	};
};

export default useCart;
