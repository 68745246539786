// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

Sentry.init({
	dsn: SENTRY_DSN || 'https://50c197ac3ed94be8ba0a05142c9229bc@o480356.ingest.sentry.io/5527389',
	// Adjust this value in production, or use tracesSampler for greater control
	// ...
	// Note: if you want to override the automatic release value, do not set a
	// `release` value here - use the environment variable `SENTRY_RELEASE`, so
	// that it will also get attached to your source maps,
	environment: process.env.NODE_ENV,
	tracesSampleRate: process.env.NEXT_PUBLIC_SENTRY_SAMPLE_RATE,
});
