import '../styles/globals.scss';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import UserContextProvider from 'lib/providers/UserContextProvider';
import CommonContextProvider from 'lib/providers/CommonContextProvider';
import PopUpContextProvider from 'lib/providers/PopUpContextProvider';
import StripeProvider from 'lib/providers/StripeProvider';
import Snackbars from 'components/Common/Snackbars';
import axios from 'axios';
import { auth } from 'lib/firebase';
import Qs from 'qs';

axios.interceptors.request.use(async function (config) {
	// Attach token to axios requests
	if (config.headers && auth.currentUser) {
		const token = await auth.currentUser.getIdToken();
		config.headers.token = token;
	}
	// Allow nested GET params
	config.paramsSerializer = (params) => {
		return Qs.stringify(params, {
			arrayFormat: 'brackets',
			encode: false,
		});
	};
	return config;
});

function MyApp({ Component, pageProps }: AppProps) {
	return (
		<>
			<UserContextProvider>
				<CommonContextProvider>
					<PopUpContextProvider>
						<StripeProvider>
							<Head>
								<title>OrderSimply</title>
								<meta
									name='description'
									content="Ontario's craft beer marketplace"
								/>
								<link rel='icon' href='icon.ico' />
								<link rel='icon' sizes='16x16' href='/icon16x16.png' />
								<link rel='icon' sizes='32x32' href='/icon32x32.png' />
								<link rel='apple-touch-icon' href='/icon256x256.png' />
								<link rel='manifest' href='manifest.json' />
								<link rel='preconnect' href='https://fonts.gstatic.com/' />
								<link
									href='https://fonts.googleapis.com/css?family=Baloo+2:400,500,600,800&amp;display=swap'
									rel='stylesheet'
								/>
							</Head>

							<Snackbars />
							<Component {...pageProps} />
						</StripeProvider>
					</PopUpContextProvider>
				</CommonContextProvider>
			</UserContextProvider>
		</>
	);
}

export default MyApp;
