import * as Sentry from '@sentry/react';
import { StripeError } from '@stripe/stripe-js';

/**
 * Logs errors to sentry
 *
 * @param {*} error
 * @param {*} text
 */
const logError = (error: Error | StripeError | any, text: string | null = null) => {
	if (process.env.NODE_ENV === 'development') {
		console.error(error);
	}
	return Sentry.captureException(error);
};

export { logError };
