import React, { useState, useMemo, useEffect } from 'react';
import { db } from 'lib/firebase';
import { CommonContext } from 'lib/context/CommonContext';
import axios from 'axios';
import { logError } from 'lib/functions/errors';
import { tag } from '@prisma/client';

const CommonContextProvider = (props: any) => {
	const [tags, setTags] = useState<tag[] | null>(null);
	const [tagMap, setTagMap] = useState<{ [key: number]: tag }>({});
	const [successMessage, setSuccessMessage] = useState<string | null>(null);
	const [errorMessage, setErrorMessage] = useState<string | null>(null);

	const value = useMemo(
		() => ({
			tags,
			tagMap,
			successMessage,
			errorMessage,
			setSuccessMessage,
			setErrorMessage,
		}),
		[tags, tagMap, successMessage, errorMessage]
	);

	// useEffect(() => {
	// 	axios
	// 		.get('/api/tags')
	// 		.then(({ data }) => {
	// 			setTags(data);
	// 			const tagMap: { [key: number]: tag } = {};
	// 			data.forEach((tag: tag) => {
	// 				tagMap[tag.id] = tag;
	// 			});
	// 			setTagMap(tagMap);
	// 		})
	// 		.catch((e) => {
	// 			logError(e);
	// 		});
	// }, []);

	return <CommonContext.Provider value={value}>{props.children}</CommonContext.Provider>;
};

export default CommonContextProvider;
