import { AutorenewRounded } from '@mui/icons-material';

const SmallLoader = () => {
	return (
		<div className='smallLoader'>
			<AutorenewRounded className='smallLoader__spinner' />
		</div>
	);
};

export default SmallLoader;
