import React, { useState, useMemo, useEffect } from 'react';
import { auth } from 'lib/firebase';
import firebase from 'firebase/app';
import { UserContext } from 'lib/context/UserContext';
import * as Sentry from '@sentry/react';
import { logError } from 'lib/functions/errors';
import useSWR, { mutate } from 'swr';
import PageLoader from 'components/Loader/PageLoader';
import { useRouter } from 'next/router';
import { User } from 'types';
import { fetcher } from 'lib/fetcher';

const safeRoutes = ['/login', '/sign-up', '/terms'];

const UserContextProvider = (props: any) => {
	const router = useRouter();
	const [authUser, setAuthUser] = useState<firebase.User | null>(null);
	const [user, setUser] = useState<User | null>(null);
	const [userLoading, setUserLoading] = useState<boolean>(true);
	const { data } = useSWR('/api/me', authUser && fetcher);

	useEffect(() => {
		if (data) {
			setUser(data);
			setUserLoading(false);
		}
	}, [data]);

	const signOut = () => {
		auth.signOut()
			.then(() => {
				setAuthUser(null);
				Sentry.configureScope((scope) => scope.setUser(null));
			})
			.catch();
	};

	const value = useMemo(
		() => ({
			userLoading,
			authUser,
			user,
			signOut,
		}),
		[authUser, user, userLoading]
	);

	useEffect(() => {
		// initial load & authentication of user
		auth.onAuthStateChanged((user: null | firebase.User) => {
			if (user) {
				setAuthUser(user);
			} else {
				setUserLoading(false);
			}
		});
		// If the user signs in with google
		auth.getRedirectResult()
			.then((result: any) => {
				if (result.credential) {
					/** @type {firebase.auth.OAuthCredential} */
					setAuthUser(result.user);
					setUserLoading(false);
				}
			})
			.catch((error: firebase.auth.AuthError) => {
				// Handle Errors here.
				const errorCode = error.code;
				const errorMessage = error.message;
				// The email of the user's account used.
				const email = error.email;
				// The firebase.auth.AuthCredential type that was used.
				const credential = error.credential;

				logError(error, `${errorMessage} - ${email} - ${credential}`);
			});
	}, []);

	useEffect(() => {
		if (authUser) {
			mutate('/api/me');

			Sentry.setUser({
				email: authUser.email ? authUser.email : 'unknown_email',
				id: authUser.uid,
			});
		}
	}, [authUser]);

	// route guard
	if (userLoading) {
		return <PageLoader />;
	}
	if (!authUser && !safeRoutes.includes(router.pathname)) {
		router.push('/login');
		return <PageLoader />;
	}
	if (user && !user.onboarding_complete && !router.pathname.includes('/onboarding')) {
		router.push('/onboarding');
		return <PageLoader />;
	}
	return <UserContext.Provider value={value}>{props.children}</UserContext.Provider>;
};

export default UserContextProvider;
