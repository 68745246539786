import { useState, useEffect, FC } from 'react';
import Modal from '@mui/material/Modal';
import Backdrop from '@mui/material/Backdrop';
import {
	AddShoppingCartRounded,
	PlusOneRounded,
	RemoveRounded,
	DeleteForeverRounded,
} from '@mui/icons-material';
import useCart from 'lib/hooks/useCart';
import { CartItem, Product } from 'types';
import axios from 'axios';
import { formatPrice, formatSKU } from 'lib/functions/display';
import { useContext } from 'react';
import { UserContext } from 'lib/context/UserContext';
import styles from 'styles/components/AddEditCartModal.module.scss';
import { product_sku } from '@prisma/client';

const AddEditCartModal: FC<{
	open: boolean;
	onClose: () => void;
	data?: CartItem;
	productId: string;
	edit?: boolean;
}> = ({ open, onClose, productId, data, edit = false }) => {
	const [sku, setSku] = useState<product_sku | null>(null);
	const [quantity, setQuantity] = useState(1);
	const [priceEstimate, setPriceEstimate] = useState(0);
	const [product, setProduct] = useState<Product | null>(null);
	const [working, setWorking] = useState(false);
	const { addCartItem, updateCartItem } = useCart(onClose, setWorking);
	const { user } = useContext(UserContext);

	const validate = () => {
		if (!product) {
			return false;
		}
		if (!sku) return false;
		return true;
	};

	useEffect(() => {
		if (!data) return;

		setQuantity(data.quantity || 1);
		setSku(data.sku || null);
	}, [data]);

	useEffect(() => {
		if (!productId) return;

		const getProduct = async () => {
			const { data } = await axios.get(`/api/products/${productId}`);
			setProduct(data);
		};

		getProduct();
	}, [productId]);

	const incrementQuantity = () => {
		setQuantity(quantity + 1);
	};

	const decrementQuantity = () => {
		if (quantity === 1) return;

		setQuantity(quantity - 1);
	};

	const handleClose = () => {
		onClose();
		setQuantity(1);
		setPriceEstimate(0);
		setProduct(null);
		setSku(null);
	};

	const onSKUSelect = (e: any) => {
		const id = parseInt(e.target.value);
		if (!id) {
			setQuantity(1);
			return setSku(null);
		}

		product?.skus.forEach((sku) => {
			if (sku.id === id) {
				setSku(sku);
			}
		});
	};

	useEffect(() => {
		if (sku) {
			setPriceEstimate(sku.price * quantity);
		}
	}, [sku, quantity]);

	const submit = () => {
		if (!sku || !product) return;

		if (edit && data) {
			updateCartItem({ id: data.id, quantity });
		} else {
			addCartItem({
				product_id: product.id,
				sku_id: sku.id,
				quantity,
			});
		}
	};

	if (!product) {
		return null;
	}
	if (!user || !user.profile || user.profile.cart === undefined) {
		return null;
	}
	return (
		<>
			<Modal
				aria-labelledby='add-product-to-cart'
				aria-describedby='add-product-to-cart-pop-up'
				className='modal'
				open={open}
				onClose={handleClose}
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500,
				}}>
				<div className={styles.inner}>
					<div className={styles.product}>
						<div
							className={styles.image}
							style={{ backgroundImage: `url(${product.image})` }}
						/>
						<div className={styles.info}>
							<h2>{product.name}</h2>
							<span>{product.storefront.name}</span>
							<h3>{`$${formatPrice(priceEstimate)}`}</h3>
						</div>
					</div>
					<div className={styles.skus}>
						{sku && (
							<div className={styles.sku}>
								{formatSKU(sku)}
								<div style={{ display: 'flex', marginLeft: '1rem' }}>
									<span className={styles.skuNumber}> {`x${quantity}`}</span>
									{quantity > 1 ? (
										<span
											className={styles.actionBtn}
											onClick={() => decrementQuantity()}>
											<RemoveRounded />
										</span>
									) : null}
									<span
										className={styles.actionBtn}
										onClick={() => incrementQuantity()}>
										<PlusOneRounded />
									</span>
									<span className={styles.actionBtn} onClick={() => setSku(null)}>
										<DeleteForeverRounded />
									</span>
								</div>
							</div>
						)}
					</div>
					<select onChange={onSKUSelect}>
						<option value={0}>~ select sku ~</option>
						{product.skus.map((sku) => (
							<option key={sku.id} value={sku.id}>
								{formatSKU(sku)} (${formatPrice(sku.price)})
							</option>
						))}
					</select>
					<button
						className='blueButton'
						disabled={!validate() || working}
						onClick={submit}>
						<AddShoppingCartRounded />
						{edit ? 'Update Cart' : 'Add Product To Cart'}
					</button>
				</div>
			</Modal>
		</>
	);
};

export default AddEditCartModal;
