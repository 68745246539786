import { location, product_sku } from '@prisma/client';
import { format, formatDistance, parseISO } from 'date-fns';
import moment from 'moment';

const isWindowsOS = (window: Window) => {
	return window.navigator.platform.indexOf('Win') !== -1;
};

const formatPrice = (price: number | null, quantity?: number) => {
	if (price === null) return 'N/A';

	if (quantity) return ((price * quantity) / 100).toFixed(2);

	return (price / 100).toFixed(2);
};

const formatSKU = (sku: product_sku) => {
	const number = sku.unit_number > 1 ? `${sku.unit_number} x ` : '';
	const unit = sku.type === 'keg' ? `${sku.unit_size_ml / 1000}L` : `${sku.unit_size_ml}ML`;
	const s = sku.unit_number > 1 ? 's' : '';
	return `${number}${unit} ${sku.type}${s}`;
};

const formatAddress = (location: location, type = 'full') => {
	return `${location.address}, ${location.city}, ${location.region} ${location.postal_code}`;
};

const formatDate = (date: any, formatString: string) => {
	if (formatString === 'fromNow') {
		return formatDistance(parseISO(date), new Date(), { addSuffix: true });
	}
	return format(parseISO(date), formatString);
};

const displayOnboardingStep = (slug: string | string[] | undefined) => {
	if (slug === 'contact') return 'Contact Info';
	if (slug === 'account-type') return 'Account Type';
	if (slug === 'business-details') return 'Business Details';
	if (slug === 'location') return 'Location Info';
	if (slug === 'licence') return 'Licence Info';
	return 'Onboarding';
};

/**
 *
 * @param {String} date moment date string from the backend
 * @param {String} mode normal or fromNow
 */
const displayDate = (date: string, mode: 'normal' | 'fromNow' = 'normal') => {
	if (mode === 'fromNow') {
		return moment(date).fromNow();
	}
	return moment(date).format('MMM Do, YYYY');
};

const tagToSlug = (tag: string) => {
	return tag.toLowerCase().replace(' ', '_');
};

const getStatusColor = (status: string) => {
	if (status === 'pending' || status === 'changed' || status === 'payment_pending') {
		return 'bg-dark-grey';
	}
	if (status === 'accepted' || status === 'scheduled' || status === 'in_transit') {
		return 'bg-yellow';
	}
	if (status === 'delivered' || status === 'charged' || status === 'paid_cheque') {
		return 'bg-green';
	}
	if (
		status === 'declined' ||
		status === 'cancelled' ||
		status === 'payment_failed' ||
		status === 'disputed'
	) {
		return 'bg-red';
	}
	if (status === 'refunded' || status === 'closed') {
		return 'bg-navy';
	}
};

export {
	formatPrice,
	formatSKU,
	formatAddress,
	formatDate,
	displayDate,
	tagToSlug,
	isWindowsOS,
	getStatusColor,
	displayOnboardingStep,
};
