import React, { useState, useMemo } from 'react';
import { PopUpContext } from 'lib/context/PopUpContext';
import AddEditCartModal from 'components/Modals/AddEditCartModal';

const PopUpContextProvider = (props: any) => {
	const [cartModalOpen, setCartModalOpen] = useState<boolean>(false);
	const [cartModalData, setCartModalData] = useState<any | null>(null);
	const [cartModalMode, setCartModalMode] = useState<any | null>(null);

	const value = useMemo(
		() => ({
			setCartModalOpen: setCartModalOpen,
			setCartModalData: setCartModalData,
			setCartModalMode: setCartModalMode,
		}),
		[]
	);

	return (
		<PopUpContext.Provider value={value}>
			<AddEditCartModal
				open={cartModalOpen}
				onClose={() => {
					setCartModalOpen(false);
					setCartModalData(null);
					setCartModalMode(null);
				}}
				productId={cartModalData ? cartModalData.product_id : null}
				data={cartModalData}
				edit={cartModalMode === 'edit'}
			/>
			{props.children}
		</PopUpContext.Provider>
	);
};

export default PopUpContextProvider;
