import { FC, ReactNode } from 'react';
import { loadStripe } from '@stripe/stripe-js/pure';
import { Elements } from '@stripe/react-stripe-js';

const stripePromise = loadStripe(
	process.env.NEXT_PUBLIC_STRIPE_PK ? process.env.NEXT_PUBLIC_STRIPE_PK : ''
);

const stripeFonts = [
	{
		cssSrc: 'https://fonts.googleapis.com/css?family=Baloo+2',
	},
];

const StripeProvider: FC<{ children: ReactNode }> = ({ children }) => {
	return (
		<Elements stripe={stripePromise} options={{ fonts: stripeFonts }}>
			<>{children}</>
		</Elements>
	);
};

export default StripeProvider;
